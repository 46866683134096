
.sidebar-content {
    background: #FFFFFF;
    box-shadow: 0px 4px 34px 0px #092C4C0A;
    height: 800px;
    padding: 15px 20px;
}

.sidebar-content .sidebar-logo {
    width: 150px;
}

.sidebar-content ul {
    padding: 0px;
    margin: 25px 0px 0px 0px;
}
.sidebar-content ul a{
    text-decoration: none;
    color: #383838 !important;
}
.sidebar-border{
    margin-top: 20px;
    border-bottom: 0.9px solid #E8E6E3;
}
.sidebar-content ul a li {
    padding: 14px 12px 14px 12px !important;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.sidebar-content ul li img {
    margin-right: 15px;
    width: 17px !important;
}

.sidebar-content ul .active {
    background: #D2AB67 !important;
    color: #fff !important;
    border-radius: 5px !important;
}

.sidebar-content ul .active img {
    filter: brightness(0) invert(1);
}

.sidebar-content ul .active a {
    color: #fff !important;
}

.logout {
    margin-top: 40px;
}

.logout .MuiButton-root {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #EB5757;
    text-transform: capitalize;
}

.logout .MuiButton-root:hover {
    color: #EB5757;
    background-color: unset !important;
}
.logout .MuiButton-root img{
    margin-right: 15px;
}
.header{
    font-size: 24px;
    color: #00A651;
}
.Login_header{
    font-size: 40px;
    color: #00A651;
    margin: 0px;
    margin-bottom: 15px;
    line-height: 35px;
}

@media (min-width:992px) and (max-width:1199px) {
    .side_left_grid{
        width: 10%;
    }
    .side_right_grid{
        width: 90%;
    }
    .side_left_grid .sidebar-content .sidebar-logo{
        width: 65px;
    }
    .sidebar-content ul{
        margin-top: 15px;
    }
    .side_left_grid .sidebar-content ul a{
        font-size: 14px;
    }
    .sidebar-content ul a li{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .side_left_grid .sidebar-content ul a li span{
        margin-top: 5px;
        font-size: 12px;
    }
    .sidebar-content ul .active{
        background: unset !important;
        color: #D2AB67 !important;
    }
    .sidebar-content ul .active img{
        filter: unset !important;
    }
    .sidebar-content ul li img{
        margin: 0;
    }
    .sidebar-content ul img{
        margin: 0;
        filter: unset !important;
    }
    .side_left_grid .logout{
        margin-top: 20px;
    }
    .side_left_grid .logout .MuiButton-root{
        display: flex;
        flex-direction: column;
        padding: 0;
        font-size: 12px;
    }
    .side_left_grid .logout .MuiButton-root img{
       margin-bottom: 5px;
    }
}
 